@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


#delete_account_button {
  border: #e51a41 1px solid;
  color: #e51a41;
  background: white;
  position: relative;
  cursor: pointer;
  transition: box-shadow 300ms ease-out, opacity 200ms linear, padding 100ms ease, transform ease 100ms;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#delete_account_button:after {
  content: "\A";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 100%;
  background: rgba(229, 26, 65, 0.2);
  transition: right 500ms linear;
}
#delete_account_button:before {
  content: "Hold to";
  position: absolute;
  top: 0rem;
  left: 0;
  width: 100%;
  font-size: 0.75rem;
  opacity: 0;
  transition: opacity linear 100ms, top ease 100ms;
}
#delete_account_button.full {
  padding-top: 1.1rem;
  padding-bottom: 0.4rem;
}
#delete_account_button.full:after {
  transition: right 1500ms linear;
  right: 0;
}
#delete_account_button.full:before {
  opacity: 1;
  top: 0.3rem;
  transition: opacity linear 100ms 100ms, top ease 100ms 100ms;
}
#delete_account_button:hover {
  box-shadow: rgba(229, 26, 65, 0.180392) 0px 0px 11px 0px, rgba(229, 26, 65, 0.14902) 0px 0px 15px 0px;
}
#delete_account_button.removed {
  opacity: 0;
  transform: scale(0.2);
}












.bg-gradient {
  background: theme('colors.body-bg-start');
  background: linear-gradient(
    160deg,
    theme('colors.body-bg-start') 0%,
    theme('colors.body-bg-end') 100%
  );
}
.location-image::before{
  background-color:rgba(0,0,0,0.3)
}
.map-image{
  border: 5px solid rgba(27, 79, 114, 0.4);
}

.dayCellEvent:nth-last-of-type(0){
  margin-bottom:0;
}
.eventStart{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.eventMiddle{
  border-radius:0;
}
.eventEnd{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dailyTimeLine{
  width: calc(100% - 4rem);
  margin-left: 4rem;
}
.w-divided-7{
  width: calc(100% / 7);
}
.dayCell > .dayDate{
  display:flex;
  justify-content: end;
  text-align:right;
  padding: 3px;
  position: relative;
  user-select: none;
}
.dayCell > .inactiveDay{
  color: #ccc;
}
.dayText{
  display:flex;
  align-items: center;
  justify-content: center;
  position:relative;
  height: 1.25rem;
  width: 1.25rem;
  padding:2px;
  line-height: 1.25rem;
  font-size: 0.9em;
  text-align: center;
}

.dayCellEventWrapper{
  display:block;
  text-align:left;
}
.dayCellEventsRemaining{
  position:absolute;
  font-size:0.6em;
  height:1rem;
  line-height:1rem;
  bottom:0;
  left:0;
  z-index:10;
}
.weekRow{
  border-bottom: 1px solid #ccc;
}
.calendarDayOfWeek{
  user-select: none;
  font-weight:300;
}
.calendarDayOfWeek > div{
  box-sizing: border-box;
  text-align:right;
  padding: 0 5px;
  width: calc(100% / 7);
  display:inline-block;
}

.dailyTimeLineWrapper{
  height:60vh;
}
.min-h-max {
  min-height: 100vh;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
